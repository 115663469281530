/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import {
  useState,
  useEffect,
} from 'react';

const useReport = ({
  isLoaderPocParameters,
  istFirstInteractionVisited,
  isSkipAvailable,
  isFirstReportSend,
  patient,
}) => {
  const [openSendReportModal, setOpenSendReportModal] = useState(false);
  const [isCancelButtonSendReport, setCancelButtonSendReport] = useState(false);
  const [firstPatientVisit, setFirstPatientVisit] = useState(null);

  useEffect(() => {
    if (isLoaderPocParameters) {
      if (istFirstInteractionVisited) {
        setFirstPatientVisit({
          email: patient.pacient_email,
          phone: patient.pacient_cellphone,
          phoneCode: patient.phone_code,
          countryCode: patient.country_code,
        });
      }

      const timer = setTimeout(() => {
        if (isFirstReportSend) {
          setCancelButtonSendReport(isSkipAvailable);
          setOpenSendReportModal(true);
        }
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [
    isLoaderPocParameters,
    istFirstInteractionVisited,
    isSkipAvailable,
    isFirstReportSend,
    patient,
  ]);

  return {
    openSendReportModal,
    isCancelButtonSendReport,
    firstPatientVisit,
    setOpenSendReportModal,
    setCancelButtonSendReport,
    setFirstPatientVisit,
  };
};

export default useReport;
